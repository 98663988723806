<template>
  <div
    class="flex items-center justify-center flex-1 w-full min-h-[calc(100vh-6rem)] px-6"
  >
    <Teleport to="body">
      <div class="fixed w-full px-2 top-20 z-50">
        <Transition name="fade">
          <AlertMessage
            v-if="message"
            :type="notificationError"
            :message="message"
            class="w-1/3 float-end"
          >
            <AlertIcon class="w-8 h-auto mr-5 min-w-8" stroke-class="" />
          </AlertMessage>
        </Transition>
      </div>
    </Teleport>
    <div
      class="flex flex-col items-start justify-center w-full h-full max-w-md"
    >
      <AlertMessage
        type="success"
        message="This feature isn’t available on the web app yet. Please use the mobile app to view your Dashbaord."
      >
        <AlertIcon class="w-8 h-auto mr-5 min-w-8" stroke-class="" />
      </AlertMessage>

      <h3 class="mt-6 mb-4 font-manrope text-[18px]">
        Help make Beesphone better for you
      </h3>

      <p class="text-sm mt-0 font-manrope text-[#13293D]">
        Is there any feature you’d like to see in the Dashboard? Let us know
      </p>

      <InputFields
        placeholder="Write Something"
        type="textarea"
        class="w-full mt-2"
        v-model="inputValue"
      />

      <Checkbox
        v-model="isChecked"
        class="my-2.5"
        label="Reach out to me with any questions"
      />

      <Button
        :title="'Submit'"
        variant="primary"
        :disabled="isSubmitting"
        size="md"
        @click="onSubmit"
        class="px-[2rem] py-2.5 mt-4 font-normal rounded-[6px]"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import AlertMessage from "~/components/common/AlertMessage.vue";
import AlertIcon from "~/components/icons/AlertIcon.vue";
import InputFields from "~/components/common/InputFields.vue";
import Button from "~/components/common/Button.vue";
import Checkbox from "./Checkbox.vue";

const inputValue = ref<string>("");
const isChecked = ref(false);
const isSubmitting = ref(false);
const message = ref("");
const notificationError = ref<"info" | "success" | "warning" | "error">(
  "success"
);
const { userDetails } = storeToRefs(useUserStore());
const authStore = useAuthStore();

const onSubmit = async () => {
  isSubmitting.value = true;
  const data = {
    UserId: userDetails.value.userId || "",
    Feedback: inputValue.value,
    ContactMe: isChecked.value,
  };

  try {
    await $fetch("/api/secure/submit-feedback", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authStore.idToken}`,
      },
    });

    notificationError.value = "success";
    message.value = "Feedback has been submitted";
    // Clear the form fields
    inputValue.value = "";
    isChecked.value = false;
  } catch (error) {
    notificationError.value = "error";
    message.value = "Failed to submit feedback";
  } finally {
    isSubmitting.value = false;
    setTimeout(() => {
      message.value = "";
    }, 2000);
  }
};
</script>