<template>
    <div class="relative flex items-center">
        <input type="checkbox" :id="label" v-model="isChecked" class="absolute top-0 left-0 hidden border size-4" />
        <svg @click="isChecked = !isChecked" width="18" height="18" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.00065 14.6673H10.0007C13.334 14.6673 14.6673 13.334 14.6673 10.0007V6.00065C14.6673 2.66732 13.334 1.33398 10.0007 1.33398H6.00065C2.66732 1.33398 1.33398 2.66732 1.33398 6.00065V10.0007C1.33398 13.334 2.66732 14.6673 6.00065 14.6673Z"
                :class="isChecked ? 'stroke-[#2979FF]' : 'stroke-slate-400'" stroke-width="1.8125" stroke-linecap="round" stroke-linejoin="round" />
            <Transition name="fade">
                <path v-if="isChecked" d="M5.16602 7.99995L7.05268 9.88661L10.8327 6.11328" stroke="#2979FF" stroke-width="1.8125"
                stroke-linecap="round" stroke-linejoin="round" />
            </Transition>
        </svg>
        <label :for="label" class="ml-2 text-sm text-[#13293D] font-manrope">{{ label }}</label>
    </div>
</template>

<script lang="ts" setup>
const isChecked = defineModel<boolean>();

defineProps<{
    label: string
}>()
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
    transform: translate(0, -10px);
}
</style>